import React, { useContext, useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { Article, Ballot, Description } from '@mui/icons-material';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { useModal, useDialog } from 'components/Modals';
import JustificativaModal from 'components/Modals/JustificativaModal';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import CustomTabs from './Tabs';
import moment from 'moment';

const Documento = () => {
  const navigate = useNavigate();
  const { onChangeTab, tab } = useOutletContext();
  const { id } = useParams();
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const { getURLEmissao } = useContext(RelatoriosContext);
  const {
    documento,
    getLoading,
    getDocumento,
    postLoading,
    postDocumento,
    deleteDocumento,
    postAnexo,
    deleteAnexo,
  } = useContext(DocumentosContext);
  const disableActions =
    Boolean(documento?.DocumentoHists?.length) &&
    documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))?.Statusdc
      ?.editar === 'NAO';
  const status_id = documento?.DocumentoHists?.filter((f) =>
    Boolean(f?.status_id)
  )?.[0]?.status_id;

  const loadDocumento = () => getDocumento(id);

  useEffect(() => {
    loadDocumento();
    onChangeTab({ tab: tab, key: id });
  }, []);

  const onSubmit = (data) =>
    postDocumento({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onSubmitAnexo = (data) =>
    postAnexo({
      data,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onDeleteAnexo = (id) =>
    deleteAnexo({
      id,
      cb: () => {
        loadDocumento();
        closeDialog();
      },
    });

  const onDelete = (params) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params,
            cb: () => {
              loadDocumento();
              closeModal();
            },
          })
        }
      />
    );

  const onDuplicate = () => {
    let data = {
      Documento: {
        cadastro_id: documento?.cadastro_id,
        celocal_id: documento?.celocal_id,
        conferido: 'NAO',
        contribuinte_id: documento?.contribuinte_id,
        dadosadc: documento?.dadosadc,
        dtemissao: documento?.dtemissao,
        especie_id: documento?.especie_id,
        filial_id: documento?.filial_id,
        financeiro_movimento_id: documento?.financeiro_movimento_id,
        gerado: 'NAO',
        indpres: documento?.indpres,
        natureza_operacao_id: documento?.natureza_operacao_id,
        observacao: documento?.observacao,
        relato: documento?.relato,
        users_id: documento?.users_id,
        usofinal: documento?.usofinal,
      },
      Financeiros: documento?.DocumentoFinanceiros?.map((f) => ({
        forma_pagto_id: f?.forma_pagto_id,
        parcela: f?.parcela,
        dtvenc: f?.dtvenc,
        valor: f?.valor,
        observacao: f?.observacao,
      })),
      Transportes: documento?.DocumentoTransportes?.map((t) => ({
        entidade_id: t?.entidade_id,
        tipotransp: t?.tipotransp,
        viatransp: t?.viatransp,
        fretepc: t?.fretepc,
        natureza: t?.natureza,
        locentrega: t?.locentrega,
        volume: t?.volume,
        tara: t?.tara,
        pesoliq: t?.pesoliq,
        pesobrt: t?.pesobrt,
        quantidade: t?.quantidade,
        especie: t?.especie,
        marca: t?.marca,
        numero: t?.numero,
        frota_id: t?.frota_id,
        motorista_id: t?.motorista_id,
      })),
      Itens: documento?.DocumentoItems?.map((i) => {
        const Estruturas = i?.DocumentoItemEstruturas?.map((e) => ({
          componente_id: e?.componente_id,
          operacao: e?.operacao,
          sequencia: e?.sequencia,
          quantidade: e?.quantidade,
          perda: e?.perda,
          volume: e?.volume,
          retornavel: e?.retornavel,
        }));
        const Processos = i?.DocumentoItemProcessos?.map((p) => ({
          operacao: p?.operacao,
          descricao: p?.descricao,
          ccusto_id: p?.ccusto_id,
          tpreparacao: p?.tpreparacao,
          texecucao: p?.texecucao,
          apontamento: p?.apontamento,
          analisa_id: p?.analisa_id,
          aprova_id: p?.aprova_id,
        }));
        const Impostos = i?.DocumentoItemImpostos?.map((imp) => ({
          tributacao_id: imp?.tributacao_id,
          tributo_id: imp?.tributo_id,
          basecalculo: imp?.basecalculo,
          percentual: imp?.percentual,
          valor: imp?.valor,
          ntributado: imp?.ntributado,
          origem_tributaria_id: imp?.origem_tributaria_id,
          situacao_tributaria_id: imp?.situacao_tributaria_id,
          modbc_id: imp?.modbc_id,
          reducao_pc: imp?.reducao_pc,
          reducao_vl: imp?.reducao_vl,
          desoneracao_mot: imp?.desoneracao_mot,
          desoneracao_vlr: imp?.desoneracao_vlr,
          diferido_pc: imp?.diferido_pc,
          mva_pc: imp?.mva_pc,
        }));
        const Variacoes = i?.DocumentoItemVariacaos?.map((vr) => ({
          variacao_id: vr?.variacao_id,
          quantidade: vr?.quantidade,
        }));
        const Margem = Boolean(i?.DocumentoItemMc)
          ? {
              custo: i?.DocumentoItemMc?.custo,
              fretend: i?.DocumentoItemMc?.fretend,
              segurond: i?.DocumentoItemMc?.segurond,
              impfednd: i?.DocumentoItemMc?.impfednd,
              comissaond: i?.DocumentoItemMc?.comissaond,
              internacional: i?.DocumentoItemMc?.internacional,
              saldo: i?.DocumentoItemMc?.saldo,
              saldoc: i?.DocumentoItemMc?.saldoc,
              medio: i?.DocumentoItemMc?.medio,
              medioc: i?.DocumentoItemMc?.medioc,
              custocompra: i?.DocumentoItemMc?.custocompra,
              custocomprac: i?.DocumentoItemMc?.custocomprac,
            }
          : null;
        let data = {
          sequencia: i?.sequencia,
          cfo_id: i?.cfo_id,
          cfiscal_id: i?.cfiscal_id,
          produto_id: i?.produto_id,
          descricao: i?.descricao,
          quantidade: i?.quantidade,
          unidade_medida_id: i?.unidade_medida_id,
          precobase_id: i?.precobase_id,
          preco: i?.preco,
          comissao_id: i?.comissao_id,
          comissao_pc: i?.comissao_pc,
          desconto_vlr: i?.desconto_vlr,
          desconto1_pc: i?.desconto1_pc,
          desconto2_pc: i?.desconto2_pc,
          desconto3_pc: i?.desconto3_pc,
          desconto4_pc: i?.desconto4_pc,
          desconto5_pc: i?.desconto5_pc,
          frete: i?.frete,
          seguro: i?.seguro,
          despac: i?.despac,
          cstfin: i?.cstfin,
          eventuais: i?.eventuais,
          outros: i?.outros,
          peso: i?.peso,
          przent: i?.przent,
          infadc: i?.infadc,
          referencia: i?.referencia,
          calcimp: i?.calcimp,
          reserva: i?.reserva,
          ccusto_id: i?.ccusto_id,
          aplicacao_id: i?.aplicacao_id,
          planoconta_id: i?.planoconta_id,
          frota_id: i?.frota_id,
          observacao: i?.observacao,
          kit_id: i?.kit_id,
        };
        if (Boolean(Estruturas?.length)) {
          data = { ...data, Estruturas };
        }
        if (Boolean(Processos?.length)) {
          data = { ...data, Processos };
        }
        if (Boolean(Impostos?.length)) {
          data = { ...data, Impostos };
        }
        if (Boolean(Variacoes?.length)) {
          data = { ...data, Variacoes };
        }
        if (Boolean(Margem)) {
          data = { ...data, Margem };
        }
        return data;
      }),
    };
    if (Boolean(documento?.DocumentoEntrada)) {
      data = {
        ...data,
        Entrada: {
          serie: documento?.DocumentoEntrada?.serie,
          dtentrada: documento?.DocumentoEntrada?.dtentrada,
          custo: documento?.DocumentoEntrada?.custo,
          ped_fornec: documento?.DocumentoEntrada?.ped_fornec,
        },
      };
    }
    if (Boolean(documento?.DocumentoSaida)) {
      data = {
        ...data,
        Saida: {
          condicao_pagamento_id:
            documento?.DocumentoSaida?.condicao_pagamento_id,
          entidade_endereco_id: documento?.DocumentoSaida?.entidade_endereco_id,
          vendedor_id: documento?.DocumentoSaida?.vendedor_id,
          ped_cliente: documento?.DocumentoSaida?.ped_cliente,
          documento: documento?.DocumentoSaida?.documento,
          dtatend: documento?.DocumentoSaida?.dtatend,
          validade: documento?.DocumentoSaida?.validade,
          desc1: documento?.DocumentoSaida?.desc1,
          desc2: documento?.DocumentoSaida?.desc2,
          desc3: documento?.DocumentoSaida?.desc3,
          desc4: documento?.DocumentoSaida?.desc4,
          desc5: documento?.DocumentoSaida?.desc5,
          forma_envio_id: documento?.DocumentoSaida?.forma_envio_id,
          freteenv: documento?.DocumentoSaida?.freteenv,
          fretecob: documento?.DocumentoSaida?.fretecob,
          freterec: documento?.DocumentoSaida?.freterec,
          divisaopc: documento?.DocumentoSaida?.divisaopc,
          divisaotp: documento?.DocumentoSaida?.divisaotp,
        },
      };
    }
    postDocumento({ data, cb: () => navigate(-1) });
  };

  const tabs = [
    {
      value: 0,
      key: 'Documento',
      label: 'Documento',
      icon: <Description />,
    },
    {
      value: 1,
      key: 'Itens',
      label: 'Itens',
      icon: <Ballot />,
    },
    {
      value: 2,
      key: 'Registros',
      label: 'Registros',
      icon: <Article />,
    },
  ];

  const options = [
    {
      name: 'Cancelar Documento',
      icon: 'cancel',
      show:
        Boolean(documento) &&
        (documento?.DocumentoHists?.every((e) => !Boolean(e?.status_id)) ||
          documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))
            ?.Statusdc?.cancelar === 'SIM'),
      action: () =>
        openDialog(
          <JustificativaModal
            documento_id={id}
            callback={() => {
              loadDocumento();
              closeDialog();
            }}
          />,
          'Cancelar Documento'
        ),
    },
    {
      name: 'Aprovar Documento',
      icon: 'rule',
      show:
        documento &&
        documento?.DocumentoItems?.some((d) =>
          d?.DocumentoItemAprovacaos?.some((a) => !Boolean(a?.aprovado))
        ),
      action: () => navigate(`/app/Documento/Aprovacoes`),
    },
    {
      name: 'Emitir Documento',
      icon: 'send',
      action: () => getURLEmissao({ data: { id }, cb: loadDocumento }),
      show: !documento?.DocumentoItems?.some((a) =>
        a?.DocumentoItemAprovacaos?.some(
          (d) => !Boolean(d?.aprovado) || d?.aprovado === 'NAO'
        )
      ),
    },
    {
      name: 'Duplicar Documento',
      icon: 'file_copy',
      action: () => onDuplicate(),
    },
    {
      name: 'Excluir Documento',
      icon: 'delete',
      show:
        Boolean(documento) &&
        (documento?.DocumentoHists?.every((e) => !Boolean(e?.status_id)) ||
          documento?.DocumentoHists?.find((f) => Boolean(f?.status_id))
            ?.Statusdc?.excluir === 'SIM'),
      action: () => deleteDocumento({ id, cb: () => navigate(-1) }),
    },
    {
      name: 'Desregistrar Documento',
      icon: 'app_registration',
      show:
        documento &&
        documento?.Especie?.registrar === 'SIM' &&
        documento?.conferido === 'SIM',
      action: () =>
        onSubmit({ Documento: { id: documento?.id, conferido: 'NAO' } }),
    },
    {
      name: 'Concluir Documento',
      icon: 'check',
      show:
        documento &&
        status_id !== 6 &&
        documento?.situacao === 'PENDENTE' &&
        documento?.Especie?.registrar === 'NAO',
      action: () =>
        onSubmit({ Registros: [{ documento_id: id, status_id: 6 }] }),
    },
  ];

  return (
    <Container>
      <Header
        titulo={`Documento: ${documento?.documento || ''}`}
        subtitulo={`Status: ${documento?.situacao || ''}`}
        outros={options}
      />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, tab) => onChangeTab({ tab, key: id })}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        drops={drops}
        documento={documento}
        getLoading={getLoading}
        disableActions={disableActions}
        loading={postLoading}
        onDelete={onDelete}
        onSubmit={onSubmit}
        onSubmitAnexo={onSubmitAnexo}
        onDeleteAnexo={onDeleteAnexo}
      />
    </Container>
  );
};

export default Documento;
